import styled, { css } from 'styled-components';

import { screen } from '@styles/mixins/breakpoints';

export const ContentWrapper = styled.div`
  max-width: 700px;
  padding: 0 64px 200px;
  margin: 0 auto;

  ${screen.l(css`
    padding: 0 16px 100px;
  `)}
`;
