import { useEffect, useRef } from 'react';

const useEventListener = (element, eventType, listener, options) => {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const wrappedListener = (evt) => listenerRef.current.call(element, evt);

    element.addEventListener(eventType, wrappedListener, options);

    return () => {
      element.removeEventListener(eventType, wrappedListener, options);
    };
  }, [element, eventType, options]);
};

export default useEventListener;
